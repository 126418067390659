import { createSlice } from "@reduxjs/toolkit";

export const AdminNewsMode = {
  CREATE: "create",
  DELETE: "delete",
  UPDATE: "update",
};

// Redux Slice
const adminNewsModeSlice = createSlice({
  name: "adminNewsModeSlice",
  initialState: {
    mode: null, // 현재 모드 (create, delete, update)
    newsId: null, // 수정 또는 삭제 시 특정 뉴스 ID
    news: null,
  },
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload; // 모드 설정
    },
    setNewsId: (state, action) => {
      state.newsId = action.payload; // 뉴스 ID 설정
    },
    setNews: (state, action) => {
      state.news = action.payload;
    },
    resetMode: (state) => {
      state.mode = null; // 모드 초기화
      state.newsId = null; // 뉴스 ID 초기화
      state.news = null;
    },
  },
});
export default adminNewsModeSlice;
export const { setMode, setNewsId, setNews, resetMode } =
  adminNewsModeSlice.actions;
