import React, { useEffect, useState } from "react";
import "../news/News.css";
import API from "utils/api";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NewsPage from "components/news/NewsPage";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import {
  AdminNewsMode,
  setMode,
  setNews,
  setNewsId,
} from "store/adminNewsMode";
import NewsUpdate from "components/admin/modalElement/NewsUpdate";
import NewsDelete from "components/admin/modalElement/NewsDelete";
import { toString } from "utils/dateToString";

const AdminDashboard = () => {
  const { t } = useTranslation();
  const [listCount, setListCount] = useState(0);
  const newslistPageNum = useSelector((state) => {
    return state.newslistPageNum.value;
  });
  const [newsList, setNewsList] = useState([]);
  const adminNewsMode = useSelector((state) => {
    return state.adminNewsMode.mode;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    API.news_count()
      .then((res) => {
        setListCount(res.data["result"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [adminNewsMode]);

  useEffect(() => {
    API.news_get(JSON.stringify([newslistPageNum, 10]))
      .then((res) => {
        console.log(res);
        if (res.data["result"] !== "null") {
          setNewsList(res.data["result"]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [newslistPageNum, adminNewsMode]);

  const onClickNewsCreate = () => {
    dispatch(setMode(AdminNewsMode.CREATE));
  };

  const onClickNewsUpdate = (news) => {
    dispatch(setMode(AdminNewsMode.UPDATE));
    dispatch(setNewsId(news.id));
    dispatch(setNews(news));
  };

  const onClickNewsDelete = (id) => {
    dispatch(setMode(AdminNewsMode.DELETE));
    dispatch(setNewsId(id));
  };

  return (
    <div className="flex flex-col items-center justify-center p-10 space-y-4">
      <div className="flex justify-end w-full ">
        <Button
          text={t(`admin.dashboard.newsCreate`)}
          cls={"py-2 px-8"}
          onClickFn={onClickNewsCreate}
        />
      </div>
      <div className="w-full ">
        {typeof newsList !== "undefined" ? (
          <div>
            <div className="grid items-center grid-cols-[2fr_4fr_2fr_3fr_1fr_1fr] h-14 font-bold border-t text-center border-b border-pointColor bg-lightBgColor">
              <div>{t(`news.listCategory`)}</div>
              <div className="text-start">{t(`news.listTitle`)}</div>
              <div>{t(`news.listPlaceOfPublication`)}</div>
              <div>{t(`news.listDateOfPublication`)}</div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                  />
                </svg>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </div>
            </div>
            <div>
              {newsList.map((news, i) => (
                <div
                  key={i}
                  className=" grid grid-cols-[2fr_4fr_2fr_3fr_1fr_1fr] h-14 text-center items-center border-b hover:bg-pointColor hover:bg-opacity-10 transition-all "
                >
                  <div className="flex justify-center">
                    <div
                      className={[
                        "px-3 border rounded-full font-bold",
                        news.category === 1
                          ? "border-red-500"
                          : news.category === 2
                            ? "border-purple-500"
                            : "border-gray-500",
                      ].join(" ")}
                    >
                      {news.category === 1
                        ? t(`news.category1`)
                        : news.category === 2
                          ? t(`news.category2`)
                          : t(`news.category3`)}
                    </div>
                  </div>
                  <a
                    target="_blank"
                    href={news.link}
                    className="overflow-hidden font-bold truncate cursor-pointer text-start whitespace-nowrap"
                    rel="noreferrer"
                  >
                    {news.title}
                  </a>
                  <div>{news.place}</div>
                  <div>{toString(news.date)}</div>
                  <div
                    className="cursor-pointer"
                    onClick={() => onClickNewsUpdate(news)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="transition-all cursor-pointer size-5 hover:scale-105"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                      />
                    </svg>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => onClickNewsDelete(news.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="transition-all cursor-pointer size-5 hover:scale-105"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
            <NewsPage />
          </div>
        ) : (
          <div>{t(`admin.dashboard.noNews`)}</div>
        )}
      </div>
      {adminNewsMode === AdminNewsMode.CREATE ? (
        <Modal element={<NewsUpdate />} />
      ) : null}
      {adminNewsMode === AdminNewsMode.UPDATE ? (
        <Modal element={<NewsUpdate />} />
      ) : null}
      {adminNewsMode === AdminNewsMode.DELETE ? (
        <Modal element={<NewsDelete />} />
      ) : null}
    </div>
  );
};

export default AdminDashboard;
