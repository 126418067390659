import axios from "axios";

if (process.env.NODE_ENV === "development") {
  axios.defaults.headers.common["x-mait-product"] = process.env.REACT_APP_TITLE;
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
} else {
  axios.defaults.headers.common["Access-Control-Allow-Origin"] =
    process.env.REACT_APP_CLIENT_URL;
}

axios.defaults.withCredentials = true;

const api = axios.create({
  baseURL: `/homepage/api`,
  headers: {
    "x-mait-product": process.env.REACT_APP_TITLE,
    "x-mait-api-version": process.env.REACT_APP_SERVER_VERSION,
  },
});

const API = {
  login: async (jsonData) => {
    const response = await api.post("/user/login", jsonData);
    return response;
  },
  news_count: async () => {
    return api.get("/news/news_count");
  },
  news_get: async (jsonData) => {
    return api.post("/news/news_get", jsonData, {
      headers: { "Content-Type": "application/json" },
    });
  },
  news_create: async (jsonData) => {
    return api.post("/news/news_create", jsonData, {
      headers: { "Content-Type": "application/json" },
    });
  },
  news_update: async (jsonData) => {
    return api.post("/news/news_update", jsonData, {
      headers: { "Content-Type": "application/json" },
    });
  },
  news_delete: async (jsonData) => {
    return api.post("/news/news_delete", jsonData, {
      headers: { "Content-Type": "application/json" },
    });
  },
  email_send: async (jsonData) => {
    return api.post("/email/email_send", jsonData, {
      headers: { "Content-Type": "application/json" },
    });
  },
  admin_login: async (jsonData) => {
    return api.post("/admin/login", jsonData, {
      headers: { "Content-Type": "application/json" },
    });
  },
  admin_validateToken: async (token) => {
    return api.post(
      "/admin/validate_token",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`, // add Token to header
        },
      }
    );
  },
};
export default API;
