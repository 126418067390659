import Button from "components/common/Button";
import React from "react";
import { use } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { resetMode } from "store/adminNewsMode";
import API from "utils/api";

const NewsDelete = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const newsId = useSelector((state) => state.adminNewsMode.newsId);

  const onClickCancle = () => {
    dispatch(resetMode());
  };

  const onClickDelete = () => {
    API.news_delete(JSON.stringify({ id: newsId }))
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
    dispatch(resetMode());
  };

  return (
    <div className="flex flex-col items-center justify-center p-10 space-y-10 pb-7">
      <div className="text-xl font-bold">
        {t(`admin.dashboard.deleteModal.titleText`)}
      </div>
      <div className="flex w-full space-x-2">
        <Button
          text={t(`admin.dashboard.deleteModal.cancelBtn`)}
          cls={"bg-darkBgColor w-1/2 py-2"}
          onClickFn={onClickCancle}
        />
        <Button
          text={t(`admin.dashboard.deleteModal.deleteBtn`)}
          cls={"w-1/2 py-2"}
          onClickFn={onClickDelete}
        />
      </div>
    </div>
  );
};

export default NewsDelete;
