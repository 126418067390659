import Button from "components/common/Button";
import InputWrap from "components/common/InputWrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AdminNewsMode, resetMode } from "store/adminNewsMode";
import API from "utils/api";

const NewsUpdate = () => {
  const news = useSelector((state) => state.adminNewsMode.news);
  const newsMode = useSelector((state) => state.adminNewsMode.mode);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    id: news?.id || "",
    category: news?.category || 0,
    title: news?.title || "",
    place: news?.place || "",
    date: news?.date || "",
    link: news?.link || "",
  });

  const isValidUrl = (value) => {
    const urlRegex =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
    return urlRegex.test(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCategoryChange = (category) => {
    setFormData((prev) => ({ ...prev, category }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isValidUrl(formData.link)) {
      alert(t(`admin.dashboard.invalidLink`));
      return;
    }

    if (newsMode === AdminNewsMode.UPDATE) {
      API.news_update(JSON.stringify(formData))
        .then((res) => {
          console.log("Update successful:", res);
        })
        .catch((err) => {
          console.error("Error updating news:", err);
        });
    }
    if (newsMode === AdminNewsMode.CREATE) {
      API.news_create(JSON.stringify(formData))
        .then((res) => {
          console.log("Create successful:", res);
        })
        .catch((err) => {
          console.error("Error creating news:", err);
        });
    }

    dispatch(resetMode());
  };

  return (
    <div>
      <form
        className="flex flex-col items-center justify-center space-y-4"
        onSubmit={handleSubmit}
      >
        <InputWrap
          inputText={t(`news.listTitle`)}
          inputcls={"w-80 sm:w-96"}
          type="text"
          name="title"
          defaultValue={formData.title}
          onChangeFn={handleChange}
          required={true}
        />
        <div className="space-y-2 w-80 sm:w-96">
          <div className="font-bold">{t(`news.category`)}</div>
          <div className="flex space-x-2">
            <div>
              <label
                htmlFor="category1"
                onClick={() => handleCategoryChange(1)}
              >
                <button
                  type="button"
                  className={[
                    "px-4 py-2 rounded-md btnAnimation",
                    formData.category === 1
                      ? "bg-pointColor text-white font-bold "
                      : "bg-lightBgColor",
                  ].join(" ")}
                >
                  {t(`news.category1`)}
                </button>
              </label>
              <input
                type="radio"
                name="category"
                id="category1"
                checked={formData.category === 1}
                onChange={handleChange}
                className="hidden"
              />
            </div>
            <div>
              <label
                htmlFor="category2"
                onClick={() => handleCategoryChange(2)}
              >
                <button
                  type="button"
                  className={[
                    "px-4 py-2 rounded-md btnAnimation",
                    formData.category === 2
                      ? "bg-pointColor text-white font-bold "
                      : "bg-lightBgColor",
                  ].join(" ")}
                >
                  {t(`news.category2`)}
                </button>
              </label>
              <input
                type="radio"
                name="category"
                id="category2"
                checked={formData.category === 2}
                onChange={handleChange}
                className="hidden"
              />
            </div>
            <div>
              <label
                htmlFor="category3"
                onClick={() => handleCategoryChange(3)}
              >
                <button
                  type="button"
                  className={[
                    "px-4 py-2 rounded-md btnAnimation",
                    formData.category === 3
                      ? "bg-pointColor text-white font-bold "
                      : "bg-lightBgColor",
                  ].join(" ")}
                >
                  {t(`news.category3`)}
                </button>
              </label>
              <input
                type="radio"
                name="category"
                id="category3"
                checked={formData.category === 3}
                onChange={handleChange}
                className="hidden"
              />
            </div>
          </div>
        </div>
        <InputWrap
          inputText={t(`news.listPlaceOfPublication`)}
          inputcls={"w-80 sm:w-96"}
          type="text"
          name="place"
          defaultValue={formData.place}
          onChangeFn={handleChange}
          required={true}
        />
        <InputWrap
          inputText={t(`news.listDateOfPublication`)}
          inputcls={"w-80 sm:w-96"}
          type="date"
          name="date"
          defaultValue={formData.date}
          onChangeFn={handleChange}
          required={true}
        />
        <InputWrap
          inputText={t(`news.link`)}
          inputcls={"w-80 sm:w-96"}
          type="text"
          name="link"
          defaultValue={formData.link}
          onChangeFn={handleChange}
          required={true}
        />
        <div className="pt-4">
          <Button
            type={"submit"}
            text={
              newsMode === AdminNewsMode.CREATE
                ? t(`admin.dashboard.newsCreate`)
                : t(`admin.dashboard.newsUpdate`)
            }
            cls={"px-8 py-2"}
          />
        </div>
      </form>
    </div>
  );
};
export default NewsUpdate;
