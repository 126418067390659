import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import store from "store/store";

import { withTranslation } from "react-i18next";
import i18next from "config/lang/i18n";

import Main from "pages/main/Main";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import TopBtn from "components/topBtn/TopBtn";
import Introduction from "pages/introduction/Introduction";
import Solution from "pages/solution/Solution";
import Customersupport from "pages/customersupport/Customersupport";
import News from "pages/news/News";
import Recruitment from "pages/recruitment/Recruitment";
import Admin from "pages/admin/Admin";
import { ProtectedRoute } from "components/admin/ProtectedRoute";
import AdminDashboard from "pages/adminDashboard/AdminDashboard";

function App() {
  return (
    <BrowserRouter basename="/homepage">
      <Provider store={store}>
        <div className="flex flex-col min-h-screen">
          <Header />
          <div className="flex-1">
            <Routes>
              <Route path="/" element={<Main />} exact />
              <Route path="/intro" element={<Introduction />} />
              <Route path="/solution" element={<Solution />} />
              <Route path="/customer" element={<Customersupport />} />
              <Route path="/news" element={<News />} />
              <Route path="/recruitment" element={<Recruitment />} />
              <Route path="/admin" element={<Admin />} />
              <Route
                path="/admin/dashboard"
                element={
                  <ProtectedRoute>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>

          <Footer />
          <TopBtn />
        </div>
      </Provider>
    </BrowserRouter>
  );
}

export default withTranslation()(App);
